/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.
*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

[dir='ltr'] .vertical-layout .main-menu .navigation a.active {
	background: linear-gradient(118deg, #fec820, rgb(254 200 32)) !important;
	color: black !important;
}

.title-company {
	margin-top: 5px;
	font-weight: 700 !important;
	color: black !important;
}

.brand-text {
	color: white !important;
}

.btn-view-emp {
	color: black;
	font-size: 11px;
	border: 1px solid #dadada !important;
	border-radius: 3px !important;
}

[dir] .btn-outline-secondary:not(:disabled):not(.disabled):active,
[dir] .btn-outline-secondary:not(:disabled):not(.disabled).active,
[dir] .btn-outline-secondary:not(:disabled):not(.disabled):focus {
	background-color: rgba(254, 200, 32, 0.2);
	color: black !important;
}

.btn-view-emp:hover {
	transition: 0.2s ease-in-out;
	color: black !important;
	background-color: #fec820 !important;
	font-size: 11px;
	border: 1px solid transparent !important;
	border-radius: 3px !important;
}

.card-company {
	border: 1px solid rgba(211, 211, 211, 0.43);
	border-radius: 10px;
	overflow: hidden;
	padding: 10px;
}

.logo-pesa {
	paint-order: fill;
	stroke-dashoffset: 17px;
	fill: rgb(254, 200, 32);
	stroke: rgb(254, 200, 32);
}

.vertical-layout.vertical-menu-modern.menu-collapsed
	.main-menu:not(.expanded)
	.navigation
	li.active
	a {
	background: #fec820 !important;
	color: black !important;
	box-shadow: none;
}

.fontetxt-pesa {
	font-family: 'Montserrat ExtraBold', serif;
	color: white !important;
	font-weight: 600 !important;
	font-size: 22px !important;
}

.main-menu.menu-light .navigation li a {
	color: #eaeaea;
	line-height: 1.45;
}

.text-primary {
	--bs-text-opacity: 1;
	color: white !important;
}

.estilo-ico-expande {
	.text-primary {
		--bs-text-opacity: 1;
		color: white !important;
	}

	color: white !important;
}

@media (max-width: 600px) {
	.oculta-mobile {
		display: none;
	}

	.main-menu .navbar-header .navbar-brand {
		margin-top: 0.5rem !important;
	}

	.brand-text {
		margin-left: -15px !important;
	}

	.main-menu .navbar-header .navbar-brand .brand-text {
		padding-left: 0;
		animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
	}
}

.estilotg {
	position: absolute;
	right: 0;
	top: 32px;
}

[dir] .main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a,
[dir] .main-menu.menu-light .navigation > li.sidebar-group-active > a {
	background: #2e2e2e !important;
	color: white;
	border-radius: 6px;
}

.main-menu.menu-light .navigation > li ul .active > a {
	color: black;
}

.main-menu.menu-light .navigation > li ul .active {
	color: black !important;
	background: linear-gradient(118deg, #fec820, rgb(250, 165, 6));
}

.main-menu.menu-light .navigation > li.active > a {
	color: black;
	background: linear-gradient(118deg, #fec820, rgb(250, 165, 6));
}

//.main-menu .navbar-header {
//  padding: 0 10px 10px!important;
//}

.main-menu.menu-light .navigation {
	background: transparent !important;
}

.main-menu {
	background-image: url('../imagens/catbg.jpg') !important;
	background-size: cover !important;
	background-position: center !important;
	//background: linear-gradient(127deg, #343434, #141414) !important;
}

//estilo login
.status-sistema {
	background-color: rgba(173, 173, 173, 0.17);
	padding: 10px;
	border-radius: 10px;
}

.text-bottom-login {
	font-size: 14px;
	font-weight: 500;
	color: black;
}

.text-title-login {
	font-family: Poppins, sans-serif;
	font-weight: 600;
	color: black;
}

.text-status-sistem {
	font-size: 13px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	margin-bottom: 5px;
	color: #232323;
}

.bg-teste {
	background-image: url('../imagens/bg-teste3.jpg');
	background-size: cover;
}

.linhalo {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.estilolootie {
	width: 500px;
}

@media (min-width: 1900px) {
	.estilolootie {
		width: 750px;
	}
}

//estilo dashboard

.titulo-info-historico {
	color: black;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}

.text-info-historico {
	color: #b9b9b9;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
}

@media (max-width: 600px) {
	.tab-ativo {
		font-size: 13px !important;
	}

	.tab-inativo {
		font-size: 13px !important;
	}

	.linha {
		height: 3px;
		background-color: #fec820;
		width: 120px !important;
		margin-top: 5px;
	}
}

.card-action {
	border: 1px #e1e1e1 solid;
	padding: 10px;
	border-radius: 8px;
}

.tab-ativo {
	cursor: default;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	color: black;
	font-size: 16px;
}

.tab-inativo {
	border: solid #d0d0d0 1px;
	color: #151515;
	border-radius: 5px;
	padding-top: 4px;
	padding-bottom: 4px;
	cursor: pointer;
	transition: 0.2s;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: -10px;
}

.tab-inativo:hover {
	background-color: #fec820;
	border: #fec820 1px solid !important;
	cursor: pointer;
	font-family: 'Poppins', sans-serif;
	transition: 0.2s;
}

.linha {
	height: 3px;
	background-color: #fec820;
	width: 150px;
	margin-top: 5px;
}

.card-action {
	justify-content: space-between;
	align-items: center;
	background-color: #f7f7f7;
	border: solid 2px #eeeeee;
	display: flex;
}

.text-info-tab {
	font-weight: 600;
	color: #b9b9b9;
	font-family: Poppins, sans-serif;
}

.divider-date {
	margin-top: 20px;
	margin-bottom: 10px;
	display: flex;
}

.linha-di {
	background-color: #e0e0e0;
	height: 1px;
	width: 100%;
	align-self: center;
}

.icone-action {
	background-color: #fec820;
	height: 40px;
	width: 40px;
	border-radius: 10px;
}

.card-inner-action {
	display: flex;
}

.botao-atalho {
	color: white;
	font-size: 12px;
	font-family: 'Poppins', sans-serif;
	align-self: center;
	text-align: center;
	border-radius: 10px;
	background: rgb(53, 53, 53);
	background: linear-gradient(
		243deg,
		rgba(53, 53, 53, 1) 0%,
		rgba(17, 17, 17, 1) 100%
	);
	padding-top: 20px;
	padding-bottom: 20px;
}

.botao-atalho:hover {
	background: #3b3b3b !important;
	cursor: pointer;
}

.card-dados-leads {
	margin-top: 10px;
	padding: 20px;
	background-color: #f6f6f6;
	border: solid 1px #d2d2d2;
}

.text-info-leads {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	color: #202020;
	font-size: 18px;
}

.text-sub-info-leads {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	color: #202020;
	font-size: 12px;
}

.dado-geral-card {
	border: solid 1px #cbcbcb;
	border-radius: 5px;
}

//estilo compra

@media (max-width: 600px) {
	.icone-normal {
		margin-left: 10px;
		cursor: pointer;
		transform: rotate(0);
		transition: 0.4s ease;
	}

	.text-date-buy {
		font-size: 12px;
	}

	.detail-buy-title {
		margin-left: 10px;
		font-size: 12px !important;
	}

	.icone-normal {
		margin-right: 5px !important;
	}

	.muda-ico {
		margin-right: 5px !important;
	}
}

.preco-compra {
	font-family: Poppins;
	font-weight: 600;
	color: black;
	font-size: 15px;
}

.full-card-buy {
	font-weight: 500;
	padding: 5px;
	background-color: #f0f0f3;
}

.detail-local-buy {
	font-weight: 500;
	font-size: 12px;
	font-family: 'Poppins', sans-serif;
	color: black;
}

.card-compra {
	border: solid 1px #dadada;
	border-radius: 5px;
}

.estilo-name-buy {
	font-size: 13px;
	font-family: 'Poppins', sans-serif;
	color: black;
	font-weight: 500;
}

.icone-normal {
	margin-left: 10px;
	cursor: pointer;
	transform: rotate(0);
	transition: 0.4s ease;
}

.muda-ico {
	margin-left: 10px;
	cursor: pointer;
	transform: rotate(180deg);
	transition: 0.4s ease;
}

.info-dados {
	font-family: 'Poppins', sans-serif;
	font-size: 13px;
	color: black;
	margin-bottom: 5px;
}

.estilo-desc-buy {
	margin-bottom: 10px;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	color: #383838;
	font-weight: 400;
}

//estilo aprovacao

.label-select-automations {
	margin-bottom: 3px;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	font-weight: 400;
	color: black;
}

.dados-info-auto {
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	background-color: #ececec;
	padding: 10px;
	color: #606060;
	border-radius: 5px;
}

.estilo-info-lead {
	background-color: #ffffff;
	border: solid 1px #c5c5c5;
	border-bottom: none;
	overflow: hidden;
	border-radius: 5px;
}

.str-col {
	border-right: 1px solid #cccccc;
}

.info-cp {
	font-size: 13px;
	font-family: 'Poppins', sans-serif;
	color: #2f2f2f;
	font-weight: 500;
}

.info-tx {
	border-bottom: solid 1px #e1e1e1;
	font-size: 13px;
	padding-bottom: 5px;
	padding-top: 7px;
	font-family: 'Poppins', sans-serif;
	color: #737373;
	font-weight: 400;
}

.detalhes-info-auto {
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	color: #606060;
	border-radius: 5px;
}

.estilo-auto-select {
	background-color: #fec820;
	color: black;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	font-weight: 400;
	padding: 5px;
	border-radius: 5px;
}

.estilo-out {
	position: fixed;
	bottom: 20px;
	left: 5%;
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
}

.estilo-inner {
	box-shadow: 0 4px 25px 0 rgb(34 41 47 / 36%);
	border-radius: 10px;
	align-self: center;
	display: flex;
	background-color: #262626;
	padding: 10px 30px 10px 30px;
	justify-content: stretch;
}

.text-envia {
	font-family: Poppins, sans-serif;
	font-weight: 400;
	color: white;
}

.btn-enviar-auto {
	background-color: #fec820 !important;
	border: none !important;
	color: #3f3f3f !important;
	padding: 10px 30px 10px 30px;
	font-family: Poppins, sans-serif;
	font-weight: 500 !important;
	font-size: 13px !important;
}

.content-text {
	font-family: Poppins, sans-serif;
	font-weight: 400;
	font-size: 15px;
	color: #262626;
}

.content-text-2 {
	font-family: Poppins, sans-serif;
	font-weight: 400;
	font-size: 13px;
	color: #262626;
}

.card-info {
	padding: 7px 30px 7px 15px;
	border: solid 1px #e0e0e0;
	border-radius: 10px;
}

.linha-horin {
	display: flex;
}

.titulo-card {
	font-family: Poppins, sans-serif;
	font-weight: 500;
	font-size: 13px;
	color: #1f1f1f;
}

.info-card {
	font-family: Poppins, sans-serif;
	font-weight: 600;
	font-size: 18px;
	color: black;
}

.min-ww {
}

//fim estilo inicio

//inicio estilo automacoes

.solicitacao-card {
	border-radius: 5px;
	padding: 10px;
	border: solid 1px #eeeeee;
}

.label-solicitacao {
	font-size: 11px;
	font-family: 'Poppins', sans-serif;
	color: black;
}

.text-solicitacao {
	margin-top: -3px;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
}

.card-auto {
	background: linear-gradient(#363636, #282828);
	min-height: 150px;
	padding: 20px;
	border-radius: 10px;
}

.status-ab {
	position: absolute;
	right: 20px;
	top: 10px;
}

.status-abc {
	position: absolute;
	right: 20px;
	bottom: 20px;
}

.ts-column {
	display: flex;
}

.icone {
	border-radius: 10px;
	width: 100%;
	background-color: #fec820;
}

.sub-card-auto {
	font-family: Poppins, sans-serif;
	font-weight: 400;
	font-size: 11px;
	color: #ffffff;
}

.data-info {
	font-family: Poppins, sans-serif;
	font-weight: 400;
	font-size: 11px;
	color: #ffffff;
}

.circle {
	display: inline-flex;
	margin-left: 10px;
	margin-bottom: -2px;
	height: 15px;
	width: 15px;
	background-color: #1cb21c;
	border-radius: 20px;
}

//página automação inner
//.tamanho-card
//{
//}

.aviso-visu {
	font-family: 'Poppins', sans-serif;
	font-size: 13px;
	font-weight: 400;
	background-color: #ff5555;
	padding: 10px;
	border-radius: 5px;
	color: white;
}

.text-status-auto {
	font-size: 13px;
	align-self: center;
}

.estilo-ativar {
	align-items: center;
	display: flex;
}

.linha-footer {
	display: flex;
	justify-content: space-between;
}

.card-auto-info {
	height: 100%;
	background-color: white;
	border: solid 1px #e5e5e5;
}

//pagina lead

.item-tab {
	align-items: center;
	display: flex;
	justify-content: center;
	align-self: center;
	min-height: 40px;
	cursor: pointer;
	text-align: center;
	background-color: white;
	padding-top: 5px;
	padding-bottom: 5px;
	border: solid 1px #e5e5e5;
}

.item-tab:hover {
	align-self: center;
	cursor: pointer;
	background-color: #f6f6f6;
	padding-top: 5px;
	padding-bottom: 5px;
	border: solid 1px #e5e5e5;
}

.item-tab-ativo {
	align-items: center;
	justify-content: center;
	align-self: center;
	min-height: 40px;
	display: flex;
	cursor: pointer;
	text-align: center;
	color: black;
	background-color: #fec820;
	padding-top: 5px;
	padding-bottom: 5px;
	border: solid 1px #fec820;
}

.status-atendimento {
	padding: 10px;
	border-radius: 5px;
	background-color: rgb(246, 246, 246);
}

.data-info-status {
	margin-top: 4px;
	font-size: 11px;
	text-align: right;
}

.nome-cliente {
	font-size: 13px;
}

.tabs-info-step {
	font-size: 12px;
	font-weight: 600;
}

.estilo-btn-tabs {
	border-color: #c4c4c4 !important;
	background-color: transparent !important;
	color: black !important;
}

// .cardAll {
// 	overflow: auto;
// }
html .content.app-content .content-area-wrapper {
	overflow: auto !important;
}
// if width is 768px or more
@media (min-width: 768px) {
	.cardAll {
		display: flex;
		justify-content: center;
	}
}

.card-kanban {
	background-color: rgb(225 225 225 / 32%);
	border-radius: 10px;
	padding: 20px;
	margin: 10px;
}

.estilo-btn-tabs:focus {
	border-color: #c4c4c4 !important;
	background-color: transparent !important;
	color: black !important;
}

.estilo-btn-tabs:hover {
	box-shadow: 0 8px 25px -8px #a8a8a8 !important;
	border-color: #c4c4c4 !important;
	background-color: transparent !important;
	color: black !important;
}

.estilo-btn-tabs-ativo {
	border-color: transparent !important;
	background-color: #fec820 !important;
	color: black !important;
}

.estilo-btn-tabs-ativo:hover {
	box-shadow: 0 8px 25px -8px #fec820 !important;
	border-color: transparent !important;
	background-color: #fec820 !important;
	color: black !important;
}

.card-info-lead {
	border-radius: 4px;
	padding: 10px;
	background-color: #f3f3f3;
}

.atendente-status {
	font-size: 12px;
	margin-top: 3px;
}

.nenhum-encontrado {
	background-color: #efefef;
	border-radius: 10px;
	text-align: center;

	.titulo-not-found {
		font-size: 16px;
		font-family: 'Poppins', sans-serif;
		color: black;
		font-weight: 500;
	}
}

.cards-esauto {
	border-radius: 4px;
	border: solid 1px #e3e3e3 !important;
	padding: 15px;
}

.titulo-detail-auto {
	font-size: 13px;
	font-family: 'Poppins', sans-serif;
	color: #1a1a1a;
	font-weight: 500;
}

.detalhes-autos {
	margin-top: 2px;
	font-family: 'Poppins', sans-serif;
	font-size: 13px;
	border-radius: 4px;
	padding: 10px;
	background-color: #efefef;
}

//clientes

.borda-infos {
	border-left: solid 1px #cecece;
}

//Atendendo

.escemplo-select {
	width: 100% !important;
}

//estilo cadastro de lead

@media (max-width: 600px) {
	.card-mobile-cad {
		border: solid 1px #dadada;
	}
}

.info-sec {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	padding-left: 25px !important;
	background-color: #eeeeee;
	padding: 10px;
}

.text-auto-inner {
	margin-top: 5px;
	font-size: 15px;
}

.separacao-auto-cad {
	border-left: solid 1px #eaeaea;
}

.text-title-cad {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 15px;
	color: #1a1a1a;
}

.label-text-cad {
	margin-bottom: 2px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 12px;
	color: #252525;
}
